.app{
  background-color: #dadbd3;
  height: 100vh;
  display: grid;
  place-items:center;
}

.app__body{
  display: flex;
  background-color: #ededed;
  height:90vh;
  width:90vw;
  margin-top: -50px;
  box-shadow: -1px 4px 20px -6px rgba(0,0,0,0.2);
}
