.sidebarChat{
	display: flex;
	padding: 20px;
	cursor: pointer;
	border-bottom: 1px solid #f6f6f6;
}
a {
	text-decoration: none !important;
	color: black;
}
.sidebarChat:hover {
	background-color: #ebebeb;
}
.sidebarChat__info > h2 {
	font-size: 16px;
	margin-bottom: 8px;
}
.sidebarChat__info{
	margin-left: 15px;
}
.sidebarChat__info > p{
	width:200px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}