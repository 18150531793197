.chat{
	flex:0.65;
	display: flex;
	flex-direction: column;
}

.chat__header {
	padding: 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid lightgray;
}

.chat__headerInfo{
	flex:1;
	padding-left: 20px;
}

.chat__headerInfo > h3{
	margin-bottom: 3px;
	font-weight: 500;
}

.chat__headerInfo > p{
	color:gray;
}

.chat__headerRight {
	display: flex;
	justify-content: space-between;
	min-width: 100px;
}

.chat__message{
	position: relative;
	font-size: 16px;
	padding: 10px;
	border-radius: 10px;
	width: fit-content;
	background-color: #ffffff;
	margin-bottom: 30px;
	overflow-wrap: break-word;
	max-width: 300px;
}

.chat__reciever{
	margin-left: auto;
	background-color: #dcf8c6;
}

.chat__timestamp{
	margin-left: 10px;
	font-size: xx-small;
}

.chat__name{
	position: absolute;
	top: -15px;
	font-weight: 800;
	font-size: xx-small;
}

.chat__body{
	flex:1;
	background-image: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
	background-repeat: repeat;
	background-position: center;
	padding: 30px;
	overflow: scroll;
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.chat__body::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.chat__footer{
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 62px;
	border-top: 1px solid lightgray;
}

.chat__footer > form{
	flex:1;
	display: flex;
}

.chat__footer > form > input {
	flex:1;
	border-radius: 30px;
	padding: 10px;
	border:none;
}
.chat__footer > form > input:focus {
	outline:none;
	border: none;
}

.chat__footer > form > button {
	display: none;
}

.chat__footer > .MuiSvgIcon-root {
	padding: 10px;
	color: gray;
}

